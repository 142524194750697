<template>
  <div class="menuwapper">
    <div v-if="menushow" class="navwrapper">
      <div class="tupainav">
        <nav-item :navItemList="navItemList" @updateMenuList="updateMenuList" />
        <!-- <div class="btnmenushow">
          <i class="el-icon-s-fold closemenu" v-if="menushow" @click="closemenu"></i>
          <i class="el-icon-s-unfold openmenu" v-else @click="openmenu"></i>
        </div> -->
      </div>
    </div>
    <div class="btnmenushow">
      <i class="el-icon-s-fold closemenu" v-if="menushow" @click="closemenu"></i>
      <i class="el-icon-s-unfold openmenu" v-else @click="openmenu"></i>
    </div>
  </div>
</template>
<script>
import NavItem from "./NavItem.vue";
// import Icon from 'element-ui'
// import "element-ui/lib/theme-chalk/icon.css";
export default {

  computed: {
    menu() {
      // alert(this.$store.state.user.menu)
      // console.log('===', this.$store.state.user.menu)
      return JSON.parse(this.$store.state.user.menu)
    }
  },
  watch: {
    menu: {
      handler(nv) {
        if (nv != null) {
          this.navItemList = this.createMenuList()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closemenu() {
      this.menushow = false;
    },
    openmenu() {
      this.menushow = true;
    },
    updateMenuList(items, sub) {
      if (sub) {
        //点击字组建
        this.navItemList = this.navItemList.map((x) => {
          x.isSelect = false;
          if (x.submenu !== undefined) {
            x.submenu = x.submenu.map((sub) => {
              if (sub.text == items.text) {
                sub.isSelect = true;
                x.isSelect = true;
                //路由跳转
                // this.$router.push(sub.route)
                this.$router.push({ name: sub.name },()=>{});
                // this.$router.push({path:sub.path})
              } else {
                sub.isSelect = false;
              }
              return sub;
            });
          } else {
            x.isSelect = false;
          }
          return x;
        });
        sessionStorage.setItem("navItemList", JSON.stringify(this.navItemList));
      } else {
        // alert('fu')
        //点击父组建
        this.navItemList = this.navItemList.map((x) => {
          // if (items.submenu === undefined) {

          //   if (x.text == items.text) {
          //     x.isSelect = true;
          //     this.$router.push({ name: items.name });
          //   } else {
          //     x.isSelect = false;
          //   }
          // }


          if (x.text === items.text) {
            if (x.submenu !== undefined) {
              x.submenu.map((item, index) => {
                if (index === 0) {
                  item.isSelect = true
                } else {
                  item.isSelect = false
                }
              })
            }
            x.isSelect = true;
          } else {
            x.isSelect = false;
          }
          this.$router.push({ name: items.name },()=>{});
          return x;

        });

        sessionStorage.setItem("navItemList", JSON.stringify(this.navItemList));
      }
    },

    //获取当前用户的权限菜单数组
    getUserMenuByUserRoute(routes) {
      let menus = []
      routes.map(item => {
        if (item.name) {
          menus.push(item.name)
        }
      })
      return menus
    },

    //根据用户拥有的menu权限生成列表
    createMenuList() {
      const menuList = [
        {
          icon: "/pic/sy2.png",
          icons: "/pic/sy1.png",
          text: "首页",
          isSelect: false,
          route: "index",
          path: "/index",
          name: "Index",
          menu: 'indexboard'
        },
        {
          icon: "/pic/csyj2.png",
          icons: "/pic/csyj1.png",
          text: "城市研究",
          isSelect: false,
          path: "/cityintro",
          name: "CityIntro",
          menu: 'cityresearch',
          submenu: [
            {
              icon: "/pic/csjj1.png",
              icons: "/pic/csjj2.png",
              text: "城市简介",
              isSelect: false,
              path: "/cityintro",
              name: "CityIntro",
              menu: 'cityintro'
            },
            {
              icon: "/pic/csqw1.png",
              icons: "/pic/csqw2.png",
              text: "城市区位",
              isSelect: false,
              path: "/citylocation",
              name: "Citylocation",
              menu: 'citylocation'
            },
            {
              icon: "/pic/dtms1.png",
              icons: "/pic/dtms2.png",
              text: "行政区划",
              isSelect: false,
              path: "/arealayout",
              name: "arealayout",
              menu: 'cityarealayout'
            },
            {
              icon: "/pic/cszc1.png",
              icons: "/pic/cszc2.png",
              text: "城市政策",
              isSelect: false,
              path: "/policy",
              name: "policy",
              menu: 'policy'
            },
            {
              icon: "/pic/csrk1.png",
              icons: "/pic/csrk2.png",
              text: "城市人口",
              isSelect: false,
              path: "/citypopulation",
              name: "citypopulation",
              menu: 'citypopulation'
            },
            {
              icon: "/pic/csjj1.png",
              icons: "/pic/csjj2.png",
              text: "城市经济",
              isSelect: false,
              path: "/econ",
              name: "econ",
              menu: 'cityecon'
            },
            {
              icon: "/pic/csgh1.png",
              icons: "/pic/csgh2.png",
              text: "城市规划",
              isSelect: false,
              path: "/layout",
              name: "layout",
              menu: 'layout'
            },

          ],
        },
        {
          icon: "/pic/bkyj2.png",
          icons: "/pic/bkyj1.png",
          text: "板块研究",
          isSelect: false,
          menu: 'blockresearch',
          path: "/blockintro",
          name: "BlockIntro",
          submenu: [
            {
              icon: "/pic/bkji1.png",
              icons: "/pic/bkji2.png",
              text: "板块简介",
              isSelect: false,
              path: "/blockintro",
              name: "BlockIntro",
              menu: 'blockintro'
            },
            {
              icon: "/pic/bkqw1.png",
              icons: "/pic/bkqw2.png",
              text: "板块区位",
              isSelect: false,
              path: "/blocklocation",
              name: "BlockLocation",
              menu: 'blocklocation'
            },
            {
              icon: "/pic/xzqh1.png",
              icons: "/pic/xzqh2.png",
              text: "板块区划",
              isSelect: false,
              path: "/blocklayout",
              name: "BlockLayout",
              menu: 'blocklayout'
            },
            {
              icon: "/pic/bkrk1.png",
              icons: "/pic/bkrk2.png",
              text: "板块人口",
              isSelect: false,
              path: "/blockpopulation",
              name: "BlockPopulation",
              menu: 'blockpopulation'
            },
            {
              icon: "/pic/bkjj1.png",
              icons: "/pic/bkjj2.png",
              text: "板块经济",
              isSelect: false,
              path: "/blockeconmic",
              name: "BlockEconmic",
              menu: 'blockeconmic'
            },
            {
              icon: "/pic/bkgh1.png",
              icons: "/pic/bkgh2.png",
              text: "板块规划",
              isSelect: false,
              path: "/blockplan",
              name: "BlockPlan",
              menu: 'blockplan'
            },
          ],
        },
        {
          icon: "/pic/tdsj2.png",
          icons: "/pic/tdsj1.png",
          text: "土地数据",
          isSelect: false,
          menu: 'landdata',
          path: "/data/notice",
          name: "Notice",
          submenu: [
           
            {
              icon: "/pic/zzgg1.png",
              icons: "/pic/zzgg2.png",
              text: "正在公告",
              isSelect: false,
              path: "/data/notice",
              name: "Notice",
              menu: 'notice'
            },
            {
              icon: "/pic/cjsj1.png",
              icons: "/pic/cjsj2.png",
              text: "成交数据",
              isSelect: false,
              path: "/data/deal",
              name: "Deal",
              menu: 'deal'
            },
            {
              icon: "/pic/nsh1.png",
              icons: "/pic/nsh2.png",
              text: "拟上市",
              isSelect: false,
              path: "/data/prepare",
              name: "Prepare",
              menu: 'prepare'

            },
            {
              icon: "/pic/zdfx1.png",
              icons: "/pic/zdfx2.png",
              text: "宗地分析",
              isSelect: false,
              path: "/data/landanalysis",
              name: "Landanalysis",
              menu: 'landanalysis'

            },
            {
              icon: "/pic/tdtj1.png",
              icons: "/pic/tdtj2.png",
              text: "推介资料",
              isSelect: false,
              path: "/data/doclibrarary",
              name: "Doclibrarary",
              menu: 'doclibrarary'
            }
          ],
        },
        {
          icon: "/pic/tpdt.png",
          icons: "/pic/tpdt1.png",
          text: "土拍地图",
          isSelect: false,
          menu: 'landdata',
          path: "/data/map",
          name: "Map",
          
        }

      ]

      const menus = this.getUserMenuByUserRoute(this.menu) //当前权限的
      const newMenu = []
      const currentroutepath = this.$route.path;

      menuList.map(item => {
        if (currentroutepath === item.path) {
          item.isSelect = true
        } else {
          item.isSelect = false
        }

        if (item.submenu && item.submenu.length) {
          let newSub = []
          item.submenu.map(subitem => {
            if (menus.indexOf(subitem.menu) > -1) {
              if (currentroutepath === subitem.path) {
                item.isSelect = true
                subitem.isSelect = true
              } else {
                subitem.isSelect = false
              }
              newSub.push(subitem)
            }
          })
          item.submenu = newSub
        }

        if (menus.indexOf(item.menu) > -1) {
          newMenu.push(item)
        }
      })

      return newMenu
    },



  },
  data() {
    return {
      menushow: true,
      navItemList:
        sessionStorage.getItem("navItemList") != null
          ? JSON.parse(sessionStorage.getItem("navItemList")) : null
      // : this.createMenuList(),

    };
  },
  components: {
    NavItem,
  },
};
</script>

<style lang="scss" >
.navwrapper {
  display: flex;
  width: 120px;
  min-height: calc(100vh - 80px);
}

.tupainav {
  position: fixed;
  width: 120px;
  background: #333640;
  // position: absolute;
  top: 70px;
  bottom: 0;
  left: 0px;
  display: flex;
  justify-content: center;
  height: auto;

  z-index: 30002;

  .menuwapper {
    top: 0px;
    z-index: 10;
    // position: relative;
    // width:auto;
    height: calc(100vh - 80px);
    // background:#333640;

  }

}


.btnmenushow {
  color: #fff;
  height: 0;
  top: 80px;
  z-index:30003;
  position: fixed;
  

  .el-icon-s-fold {
    position: relative;
    color: #fff;
    font-size: 20px;
    top: 5px;
    right: -95px;
    cursor: pointer;
  }

  .el-icon-s-unfold {
    position: absolute;
    font-size: 20px;
    bottom: -28px;
    right: -20px;
    // color: #000;
    color: #fff;
    background: #333640;
    border-radius: 0 2px 2px 0;
    z-index: 200000;
    cursor: pointer;

    &:hover {
      // color: #fff;
      // color: rgb(6, 136, 234);
    }
  }
}



// .closemenu {
//   position: fixed;
// }

// .openmenu {
//   height:100px;
//   width:100px;
//   background:red;
//   position: fixed;
// }
</style>