<template>
  <div>
    <div class="headerTop">
      <div class="headeritem">
        <div class="logo">
          <img class="logoimg" src="/pic/logo.png" />
        </div>

        <div v-if="!hideTopCity" class="changelocation">
          <span class="labelselect">城市选择</span>

          <div class="citycompenent">
            <el-select class="inputone" filterable @change="changeO1" size="mini" v-model="c_sheng" placeholder="请选择">
              <el-option v-for="item in options1" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>

            <el-select size="mini" filterable @change="changeshi" class="inputtwo" v-model="c_shi" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>

            <el-select size="mini" v-if="!hideQuBlock" filterable @change="changequ" class="inputqu" v-model="c_qu"
              placeholder="请选择">
              <el-option v-for="item in options3" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>

            <el-select size="mini" v-if="!hideQuBlock" filterable @change="changeblock" class="inputblock"
              v-model="c_block" placeholder="请选择">
              <el-option v-for="item in options4" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>


            <el-button type="primary" icon="el-icon-refresh" class="changeBtn" @click="changeCity" size="mini">切换
            </el-button>
          </div>
        </div>

      </div>
      
      <div class="headeritemright">
       
        <div @click="towebsite" class="guanwang">
          <span class="text">官方网站</span>
          <img  src="@/assets/icon/arrowright.png" alt="">
        </div>
        <div class="headertip">
        </div>
        <ucenter-menu></ucenter-menu>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ucenterMenuVue from './login/ucenterMenu.vue';
import { Cascader, Select, Option, Button, Col } from 'element-ui';
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
import 'element-ui/lib/theme-chalk/select.css';
import 'element-ui/lib/theme-chalk/cascader.css';
import 'element-ui/lib/theme-chalk/option.css';
import 'element-ui/lib/theme-chalk/button.css';

import { citylist, hasBlock } from '../api/cityresearch'
import UcenterMenu from './login/ucenterMenu.vue';
export default {
  data() {
    return {
      hideTopCity: false,
      hideQuBlock: true,
      activeIndex: "1",
      activeIndex2: "1",
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      c_sheng: null,
      c_shi: null,
      c_qu: null,
      c_block: null,
      c_cname:'', //当前城市的名称,用于综合交通网络判断使用

    };
  },
  components: {
    ucenterMenuVue,
    UcenterMenu
  },

  watch: {
    $route(to, from) {
      this.initblock(to.meta.areaMode)
    }
  },
  mounted() {
    this.initblock(this.$route.meta.areaMode)
    this.initcitydata()
  },

  computed: {
    sheng: {
      get() {
        const a = this.$store.state.sheng
        this.c_sheng = a;
        // console.log('sheng', a)
        // const a = 1
        return a;
      },
      set(val) {
        this.$store.dispatch('changeSheng', val)
      }
    },
    shi: {
      get() {
        const a = this.$store.state.shi
        // console.log('shi', a)
        this.c_shi = a
        // this.c_cname = 
        return a
      },
      set(val) {
        this.$store.dispatch('changeShi', val)
      }
    },

    qu: {
      get() {
        const a = this.$store.state.qu
        this.c_qu = a
        return a
      },
      set(val) {
        this.$store.dispatch('changeQu', val)
      }
    },

    block: {
      get() {
        const a = this.$store.state.block

        this.c_block = a
        return a
      },
      set(val) {
        this.$store.dispatch('changeBlock', val)
      }
    },

    city_plate_id: {
      get() {
        const a = this.$store.state.city_plate_id
      },
      set(val) {
        this.$store.dispatch('changeCityPlateId', val)
      }
    },

    cname: {
      get() {
        const a = this.$store.state.cname
    
        return a
      },
      set(val) {
        this.$store.commit('changeCname', val)
      }

    },

    value: {
      get() {
        return this.$store.state.currentCityArr;

      },
      set(val) {

        this.$store.dispatch('changeValue', {
          currentCityValue: val
        })

      }
    },
    cityoptions: function () {
      let option = this.$store.state.cityoptions
      return option;
    }

  },

  methods: {
    towebsite(){
      window.open("https://chinatupai.com")
    },

    initblock(data) {
      switch (data) {
        case 'hide':
          this.hideTopCity = true
          break;
        case 'city':
           this.c_cname = this.cname
          this.hideTopCity = false
          this.hideQuBlock = true

          this.c_sheng = this.sheng
          this.changeO1(this.sheng)

          this.c_shi = this.shi
          this.changeshi(this.shi)

          this.city_plate_id = this.c_shi
          break;
        case 'block':

          this.c_cname = this.cname
          this.c_sheng = this.sheng
          this.changeO1(this.sheng)

          this.c_shi = this.shi
          this.changeshi(this.shi)

          this.c_qu = this.qu
          this.changequ(this.qu)

          this.c_block = this.block
          this.changeblock(this.block)

          if (this.c_block == null || this.c_qu == null) {
            hasBlock({ shi: this.shi }).then(res => {
              this.sheng = res.data.sheng
              this.changeO1(this.sheng)
              this.shi = res.data.shi
              this.changeshi(this.shi)
              this.qu = res.data.qu
              this.changequ(this.qu)
              this.block = res.data.block
              this.changeblock(this.block)
            })
          }

          this.hideTopCity = false
          this.hideQuBlock = false
          this.city_plate_id = this.c_block
          break;
        default:
          this.hideTopCity = false
          this.hideQuBlock = true
          this.city_plate_id = this.c_shi
          break;

      }
    },


    initcitydata() {
      citylist().then(res => {

        this.$store.commit('citylist', res.data)

        const list = res.data
        const newOptions1 = []
        const newOptions2 = []
        const newOptions3 = []
        const newOptions4 = []
        this.$store.state.cityoptions.map(item => {
          const subs = {
            label: item.label,
            id: item.id
          }
          if (this.sheng === item.id) {
            if (item.children && item.children.length) {
              item.children.map(i => {
                const son = {
                  label: i.label,
                  id: i.id
                }

                if (this.shi === i.id) {
                  if (i.children && i.children.length) {
                    i.children.map(qu => {
                      const quson = {
                        label: qu.label,
                        id: qu.id
                      }
                      newOptions3.push(quson)
                      if (this.qu === qu.id) {
                        if (qu.children && qu.children.length) {
                          qu.children.map(block => {
                            const blockson = {
                              label: block.label,
                              id: block.id
                            }
                            newOptions4.push(blockson)
                          })
                        }
                      }

                    })
                  }

                }
                newOptions2.push(son)

              })
            }

          }


          newOptions1.push(subs)
        })

        this.options1 = newOptions1;
        this.options2 = newOptions2;
        this.options3 = newOptions3;
        this.options4 = newOptions4;
        this.c_shi = this.shi
        if (this.qu == null) {
          this.changeshi(this.c_shi)
        } else {
          this.c_qu = this.qu
        }

        if (this.block == null) {
          this.changequ(this.c_qu)
        } else {
          this.c_block = this.block
        }


      })

    },


    changeblock(e) {
      // this.block = e
      this.c_block = e
    },

    changequ(e) {
      // alert('changequ')
      // alert(e)
      // this.qu = e
      this.c_qu = e
      let BlockArr = []
      this.$store.state.cityoptions.map(sheng => {
        if (sheng.id === this.c_sheng) {
          if (sheng.children && sheng.children.length) {
            sheng.children.map(shi => {
              // console.log('this.shi', this.shi)
              if (shi.id === this.c_shi) {
                if (shi.children && shi.children.length) {
                  shi.children.map(qu => {
                    if (qu.id === e) {
                      BlockArr = qu.children
                    }
                  })
                }

              }
            })
          }
        }
      })

      if (BlockArr && JSON.stringify(BlockArr) !== '[]') {

        this.options4 = BlockArr

        this.c_block = parseInt(BlockArr[0].id);
      } else {
        this.options4 = []
        this.c_block = null;
        // this.block = null;
      }

    },

    changeshi(e) {

      // this.shi = e
      this.c_shi = e
      // this.shi = e
      var that = this
      let quArr = []
      this.$store.state.cityoptions.map(sheng => {
        if (sheng.id === this.c_sheng) {
          if (sheng.children && sheng.children.length) {
            sheng.children.map(shi => {
             
              if (shi.id === e) {
                that.c_cname = shi.label //存储当前城市的名称 用于区位交通
                quArr = shi.children
                
              }
            })
          }
        }
      })

      if (quArr && JSON.stringify(quArr) !== '[]') {
        this.options3 = quArr
        this.c_qu = parseInt(quArr[0].id);

        this.changequ(this.c_qu)
      } else {
        this.options3 = []
        this.options4 = []
        this.c_qu = null;
        this.c_block = null;
        // this.qu = null
        // this.block = null
      }

    },

    //更改省级选择
    changeO1(e) {
      // this.sheng = e
      this.c_sheng = e
      //组合市级数据
      let citys = []
      this.$store.state.cityoptions.map(item => {
        if (item.id === e) {
          citys = item.children
        }
      })

      if (citys && JSON.stringify(citys) !== '[]') {
        this.options2 = citys
        this.c_shi = parseInt(citys[0].id);
        this.changeshi(this.c_shi)
      } else {
        this.options2 = []
        this.options3 = []
        this.options4 = []
        this.c_shi = null;
        this.c_qu = null;
        this.c_block = null;
        // this.shi = null;
        // this.qu = null
        // this.block = null
      }

    },

    //更改城市
    changeCity() {
      if (this.c_sheng == null || this.c_sheng === '') {
        this.$alert('请选择一个省', '提示', { confirmButtonText: '确定' })
        return
      }
      if (this.c_shi == null || this.c_shi === '') {
        this.$alert('请选择一个市', '提示', { confirmButtonText: '确定' })
        return
      }
      if (!this.hideQuBlock) {
        if (this.c_qu == null || this.c_qu === '') {
          this.$alert('请选择一个区', '提示', { confirmButtonText: '确定' })
          return
        }
        if (this.c_block == null || this.c_block === '') {
          this.$alert('请选择一个板块', '提示', { confirmButtonText: '确定' })
          return
        }

      }


      this.sheng = this.c_sheng
      this.shi = this.c_shi
      this.qu = this.c_qu
      this.block = this.c_block
      this.cname = this.c_cname

    },


    handleSelect(key, keyPath) {
    },
  },
};
</script>

<style lang="scss">
.guanwang{
  background: rgba(255,255,255,0.1);
border-radius: 4px;
height:40px;
width:104px;
display: flex;
justify-content: space-around;
align-items: center;
margin-right:20px;
.text{
  display: inline-block;
  font-size: 16px;
  color:#fff;
  cursor: pointer;
}
  img{
    width:18px;
    height:18px;
    font-size: 16px;
    color:#fff;
  }

}
.inputone,
.inputtwo {
  width: 100px;
  margin-left: 5px;
}

.inputqu {
  width: 100px;
  margin-left: 5px;
}

.inputblock {
  min-width: 180px;
  width: auto;
  max-width: 400px;
  margin-left: 5px;
}

.changeBtn {
  width: 80px;
  margin-left: 50px;
}

.headerTop {
  position: fixed;
  margin-bottom:80px;
  height: 80px;
  width: 100%;
  background: #333640;
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 3px #888888;
  ;
  z-index:200;
}

.cascader {
  width: 100px;
  color: rgb(17, 146, 197);
}


.headeritemright {
  // background:red;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width:260px;
}

.headeritem {
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    .logoimg {
      margin-left: 6px;
      margin-top: 4px;
      width: 116px;
      height: 50px;
    }
  }

  .changelocation {
    height: 30px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    margin-left: 40px;
    display: flex;
    align-items: center;

    .citycompenent {
      margin-left: 10px;

      .changeBtn {
        margin-left: 10px;
      }
    }

  }

  .select {
    width: 260px;
    height: 40px;
    background: #F0F3F8;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('/pic/search.png') left 30px top 12px no-repeat #F0F3F8;
    background-size: 20px 20px;

    .searchinput {
      height: 26px;
      width: 180px;
      border: none;
      outline: none;
      margin-left: 52px;
      background: #F0F3F8;

      &:focus {
        background: url('');
      }
    }
  }

  .headertip {
    background:blue;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    font-size: 12px;

    .tipimg {
      width: 20px;
      height: 20px;

    }

    .paoinfo {
      display: block;
      height: 12px;
      padding: 1px 3PX;
      border: solid 1px #f00;
      background: #f00;
      border-radius: 15px;
      position: absolute;
      top: -12px;
      left: 5px;
      color: #fff;
      font-size: 12px;
    }

  }

  .line {
    height: 40px;
    border: none;
    width: 1px;
    background: #ccc;
    margin-right: 12px;
    margin-left: 10px;
  }

  .photo {
    height: 30px;
    width: 30px;
    cursor: pointer;

    font-size: 12px;

    .photopic {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  .usrname {
    cursor: pointer;
    font-size: 14px;
    margin-left: 12px;
    margin-right: 62px;
    color: #fff;
  }

}
</style>