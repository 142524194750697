import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'lib-flexible' //rem转化器



import 'leaflet/dist/leaflet.css';
// import '@/assets/styles/fonts.css';  
/* leaflet icon */
// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });




// import BaiduMap  from 'vue-baidu-map'
// import VueBMap from 'vue-bmap-gl';
// import 'vue-bmap-gl/dist/style.css'
// import VueMapvgl from 'vue-mapvgl';
// VueBMap.initBMapApiLoader({
//   ak: 'oe2YIR6kRoAbqlsbMA1NTFzUttRNQLLL',
//   v: '1.0',
//   plugins:'DistanceTool'
// });



// import BaiduMap from 'vue-baidu-map'

// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'oe2YIR6kRoAbqlsbMA1NTFzUttRNQLLL'
// })



// Vue.use(VueBMap);
// Vue.use(VueMapvgl);

Vue.prototype.$axios = axios

// console.log(VueBMap)
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '/public/css/main.css'

import './styles/element-variables.scss'
import MoQuarterPicker from "mo-quarter-picker";
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MoQuarterPicker);

//引入
import '@/permission' // permission control
//引入自定义按钮权限指令类
import directivePermission from '@/utils/directivePermission.js'
//全局注册按钮权限指令
Vue.directive('permission', directivePermission)

// import scrollx from '@/directive/tableScroll/index.js'
// Vue.directive('scrollx', scrollx)



// import Plugin from 'v-fit-columns';
// Vue.use(Plugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')