<template>
  <div class="home" style="min-width:1320px;width:100%">
      <nav-top></nav-top>
      <div class="main">
        <nav-menu></nav-menu> 
        <router-view :key="$route.fullPath" />
      </div>
      <div class="pop">
        <el-popover
            placement="bottom"
            title="lodding"
            width="200"
            trigger="manual"
            :content="noticetitle"
            v-model="c_notice">
        </el-popover>
    </div>
  </div>
</template>

<script>
import NavTop from '@/components/NavTop.vue'
import NavMenu from '@/components/NavMenu.vue'

export default {
  name: 'Home',
  components: {
    NavTop,
    NavMenu,
    // ElementNav

  },
  data(){
    return {
      c_notice:false,
    }
  },
  computed:{
    notice:{
       get(){
              return this.$store.state.notice
          },
          set(v){
              this.$store.commit('notice',v)
          }
      },
      noticetitle:{
          get(){
              return this.$store.state.noticetitle
          },
          set(v){
              this.$store.commit('noticetitle',v)
          }
      }
  },
  watch:{
    notice(v){
      this.c_notice = v;
    }
  }
}
</script>
<style lang="scss">
  .main{
    display: flex;
    margin-top:80px;
    min-height:calc(100vh - 80px);
    height:auto;
  }
  .home{
    position: relative;
    overflow: scroll;
  }
  .pop{
    display: none;
    position: absolute;
    margin:auto;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height:100px;
    width:200px;
}
</style>
