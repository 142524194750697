import {
  login,
  logout,
  getInfo
} from '@/api/user'

import {
  getToken,
  setToken,
  removeToken,
  setSuper,
  getSuper
} from '@/utils/auth'
import router, {
  resetRouter
} from '@/router'

const state = {
  token: getToken(),
  name: '',
  menu: sessionStorage.getItem('menu') === undefined ? '' : sessionStorage.getItem('menu'),
  button: sessionStorage.getItem('button') === undefined ? '' : sessionStorage.getItem('button'),
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_MENU: (state, menu) => {
    state.menu = JSON.stringify(menu)
    sessionStorage.setItem('menu', JSON.stringify(menu));

  },


  SET_BUTTON: (state, button) => {
    state.button = JSON.stringify(button)
    sessionStorage.setItem('button', JSON.stringify(button));

  },

  
}

const actions = {
  // user login
  login({
    commit
  }, userInfo) {
    const {
      account,
      password
    } = userInfo
    return new Promise((resolve, reject) => {
      login({
        account: account.trim(),
        password: password
      }).then(response => {
        const {
          data
        } = response.data
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        setSuper(data.is_super)

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const {
          data
        } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        // const { id, account, roles } = data
        const {
          id,
          truename,
          menu,
          button
        } = data
        // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject('getInfo: roles must be a non-null array!')
        // }
        // alert(truename)
        // commit('SET_ROLES', roles)
        commit('SET_NAME', truename)
        commit('SET_MENU', menu)
        commit('SET_BUTTON', button)
        // commit('userinfo', data)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },




  // user logout
  logout({
    commit,
    state,
    dispatch
  }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_', '')
        commit('SET_ROLES', '')
        removeToken()
        resetRouter()

        // this.$store.commit('setToken', '')
        // localStorage.setItem('token', '')
        // Cookies.set('token', '')
        // this.$store.commit('userinfo', '')
        // Cookies.set('userinfo', '')
        // Cookies.set('name', '')


        this.$store.commit('setToken', '')
				localStorage.setItem('token', '')
				Cookies.set('token', '', {
					path: '/',
					domain: process.env.VUE_APP_DOMAIN
				})

				Cookies.remove('token')
				this.$store.commit('userinfo', '')
				Cookies.set('userinfo', '', {
					path: '/',
					domain: process.env.VUE_APP_DOMAIN
				})
				Cookies.remove('userinfo')
				


        

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, {
          root: true
        })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({
    commit,
    dispatch
  }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const {
      roles
    } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, {
      root: true
    })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, {
      root: true
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}