import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

import Cookies from 'js-cookie'
import {
  deepCopy
} from './deepcopy.js'
import createPersistedState from "vuex-persistedstate";

import {
  info
} from '@/api/member'

Vue.use(Vuex)
/**
 * 获得能通过第二个函数参数的值
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  const {
    length
  } = list
  let index = 0
  let value
  while (++index < length) {
    value = list[index]
    if (f(value, index, list)) {
      return value
    }
  }
}

import {
  citylist
} from '../api/cityresearch.js'

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({


  state: {
    currentRoute: {},
    noticetitle: '', // views/home.vue  定义的全局提示信息标题
    notice: false, // views/home.vue  定义的全局提示信息状态  默认false 不显示 设置为ture时显示 

    currentArea: 0,
    cname: localStorage.getItem('cname') || '', //当前区县的名称
    //当前省
    sheng: parseInt(localStorage.getItem('sheng')) || null,
    //当前城市
    shi: parseInt(localStorage.getItem('shi')) || null,
    //当前区
    qu: parseInt(localStorage.getItem('qu')) || null,
    //当前板块
    block: parseInt(localStorage.getItem('block')) || null,

    city_plate_id: parseInt(localStorage.getItem('city_plate_id')) || null,

    currentCityArr: [],
    cityoptions: [],
   
    loginnav: [{
        id: 1,
        name: '数据中心',
        link: 'https://chinatupai.com',
        isSelect: true
      },
      {
        id: 2,
        name: '官方网站',
        link: 'https://chinatupai.com',
        isSelect: false
      },
      {
        id: 3,
        name: '业务合作',
        link: 'https://chinatupai.com',
        isSelect: false
      }, {
        id: 4,
        name: '关于我们',
        link: 'https://chinatupai.com',
        isSelect: false
      }

    ],
    loginMode: localStorage.getItem('loginMode') || 'telphone', //scan 扫码   telphone  电话号码  msg 信息验证码
    membersWechatScanId: localStorage.getItem('membersWechatScanId') || '',
    membersId: localStorage.getItem('membersId') || '',


    currentLandData: JSON.parse(localStorage.getItem('currentLandData')) || {}, //当前宗地地块

    // token: localStorage.getItem('token') || '',
    userinfo: Cookies.get('userinfo') === undefined ? '' : Cookies.get('userinfo'),
    token: Cookies.get('token') === undefined ? '' : Cookies.get('token'),
    condition: JSON.parse(localStorage.getItem('condition')) || {
      city_plate_id: [14, 16, 18, 73, 74],
      usecate: [1, 2, 3, 4],
      search: '',
      tradedate: [],
      biddingst:[],
      affordtype: false
    },
    mapmode: localStorage.getItem('mapmode') || 'table',
    currentzoom:parseInt(localStorage.getItem('currentzoom')) || 12
    //当前宗地地块

  },
  getters: {
    ...getters,
    currentAreaList: function (state) {
      const source = JSON.parse(JSON.stringify(state.cityoptions))

      let newdata = [];
      newdata = source.map(sheng => {

        if (sheng.children != null) {

          sheng.children.map(shi => {
            if (shi.children != null) {
              const current = {
                id: shi.id,
                // label: shi.label + '市'
                label: shi.label 
              }
              shi.children.unshift(current)
            } else {
              shi.children = [{
                id: shi.id,
                label: shi.label 
                // label: shi.label + '市'
              }]
            }

            return shi;
          })
        }
        return sheng;
      })
      // console.log('handlafteredata',newdata)
      // state.cityoptions = data



      let currentshi;
      newdata.map(item => {
        if (state.sheng === item.id) {
          if (item.children != null) {
            currentshi = item.children.filter(i => {
              return i.id === state.shi
            })
          } else {
            return []
          }

        }
      })

      if (currentshi !== undefined) {
        state.currentCity = currentshi[0].children;
        //判断是否有选中的id 若为空初始化为第一个对象的id
        if (state.currentArea === 0) {
          state.currentArea = currentshi[0].children[0].id
        }
        return currentshi[0].children;
      }

    },
    token: function (state) {
      return state.token
    },
   
  },
  mutations: {
    currentzoom(state, v) {
      localStorage.setItem('currentzoom', JSON.stringify(v))
      state.currentzoom = v
    },
    
    condition(state, v) {
      localStorage.setItem('condition', JSON.stringify(v))
      state.condition = v
    },

    mapmode(state, v) {
      state.mapmode = v
      localStorage.setItem('mapmode', v)
    },

    setRoute(state, v) {
      state.currentRoute = v
    },

    currentLandData(state, v) {
      localStorage.setItem('currentLandData', JSON.stringify(v))
      state.currentLandData = v
    },

    userinfo(state, v) {
      // console.log('cunchu', JSON.stringify(v))
      state.userinfo = JSON.stringify(v)
      // Cookies.set('userinfo', JSON.stringify(v), 1, '.chinatupai.com')

      Cookies.set('userinfo', JSON.stringify(v), {
        path: '/',
        expires: 7,
        domain: process.env.VUE_APP_DOMAIN
      })

    },

    setToken(state, v) {
      localStorage.setItem('token', v)
      state.token = v
    },

    setMembersId(state, v) {
      localStorage.setItem('membersId', v)
      state.membersId = v
    },

    setMembersWechatScanId(state, v) {
      localStorage.setItem('membersWechatScanId', v)
      state.membersWechatScanId = v
    },

    setLoginMode(state, v) {
      localStorage.setItem('loginMode', v)
      state.loginMode = v
    },

    changeCname(state, v) {
      localStorage.setItem('cname', v)
      state.cname = v
    },

    currentArea(state, v) {
      state.currentArea = v
    },

    noticetitle(state, v) {
      state.noticetitle = v
    },
    notice(state, v) {
      state.notice = v
    },
    changeValue(state, obj) {

      state.currentCity = obj.currentCity;
      state.currentCityArr = obj.currentCityArr;
      state.cname = state.currentCity.label
    },
    changeSheng(state, v) {
      state.sheng = v;
    },
    //改变市
    changeShi(state, v) {
      state.shi = v
    },
    //改变区
    changeQu(state, v) {
      state.qu = v
    },
    //改变板块
    changeBlock(state, v) {
      state.block = v
    },

    //当前城市
    currentCity(state, v) {
      state.currentCity = v
    },

    //当前城市
    citylist(state, data) {


      state.cityoptions = data

    },


    changeCityPlateId(state, v) {
      state.city_plate_id = v
    },




  },
  actions: {
    //获取用户信息
    userinfo({
      commit
    }) {
      info().then(res => {

      

        const userinfo = res.data
        commit('userinfo', userinfo)
      })
    },

    //更改当前城市
    currentCity({
      commit
    }, obj) {
      commit('currentCity', obj.children)
    },

    changeValue({
      commit
    }, obj) {

      let currentCityValue = obj.currentCityValue
      // this.state.currentCityArr = currentCityValue
      let all = this.state.cityoptions
      // console.log(all)
      let c = [];
      // console.log(all)
      all.filter(item => {
        item.children.filter(i => {
          if (i.value == currentCityValue[1]) {
            c = deepCopy(i);
          }
          return i
        })
        return item;
      })
      let o = {
        currentCity: c,
        currentCityArr: currentCityValue
      }
      commit('changeValue', o)
    },
    //改变省
    changeSheng({
      commit
    }, v) {
      localStorage.setItem('sheng', v)

      commit('changeSheng', v)
    },
    //改变市
    changeShi({
      commit
    }, v) {
      localStorage.setItem('shi', v)

      commit('changeShi', v)
    },
    //改变区
    changeQu({
      commit
    }, v) {
      localStorage.setItem('qu', v)
      commit('changeQu', v)
    },

    //改变区
    changeBlock({
      commit
    }, v) {
      localStorage.setItem('block', v)
      commit('changeBlock', v)
    },

    //citypalteid
    changeCityPlateId({
      commit
    }, v) {
      localStorage.setItem('city_plate_id', v)
      commit('changeCityPlateId', v)
    }


  },
  modules,

})





// const store = new Vuex.Store({
//   modules,
//   getters
// })

// export default store