//v-permission 指令制作
import store from '@/store'
export default function (el, binding,vnode) {
  const arg = binding.arg  //：后面的参数  当前参数
  const buttonPermission = store.state.user.button  //后面的参数
  const per = buttonPermission.filter(cur=>{
    return cur.name === arg
  })

  if(!per.length){ //无权限处理  不显示节点（注意这里使用的是v-if  不是显隐）
      const comment = document.createComment(' ');
      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });
      vnode.elm = comment;
      vnode.text = ' ';
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;
      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }
      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
  }
}