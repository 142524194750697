<template>
	<div class="ucenterbox" v-if="showUcenter">
		<el-avatar shape="square" :size="30" :src="info.avatar">
		</el-avatar>
		<el-dropdown>
			<span class="el-dropdown-link">
				<span style="color:#fff;margin-left:10px;">{{ info.truename }}</span><i
					class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item @click.native="toUcenter">会员中心</el-dropdown-item>
				<el-dropdown-item @click.native="toLogout">退出登录</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>
<script>

import Cookies from 'js-cookie'
import { logout } from '@/api/member'
export default {
	name: 'WorkspaceJsonUcenterMenu',

	data() {
		return {
			showUcenter: true,
			info: {

			}
		};
	},

	computed: {
		userinfo: {
			get() {
				this.info = this.$store.state.userinfo

				return this.$store.state.userinfo !== '' ? JSON.parse(this.$store.state.userinfo) : ''
			},
			set() {
				this.$store.dispatch('userinfo')
			}
		},
		token: {
			get() {
				// alert(this.$store.state.token)
				return this.$store.state.token
			}
		}
	},

	watch: {
		userinfo: {
			handler(nv) {
				// alert(this.token)
				this.info = nv

	
				if (nv === '' && this.token) {

					this.$store.dispatch('userinfo')
				}
				if (nv === '' && this.token === '') {
					this.showUcenter = false
				} else {
					this.showUcenter = true
				}


			},
			immediate: true,
			deep: true
		}
	},


	methods: {
		//会员中心
		toUcenter(v) {
			const usercenter = process.env.VUE_APP_LOGIN_BASIC
			window.location.href = usercenter + 'member/buyvip'
		},


		//退出
		toLogout() {
			logout().then(res => {

				// this.$store.commit('setToken', '')
				// localStorage.setItem('token', '')
				// Cookies.set('token', '')
				// this.$store.commit('userinfo', '')
				// Cookies.set('userinfo', '')
				// Cookies.set('name','')

				this.$store.commit('setToken', '')
				localStorage.setItem('token', '')
				Cookies.set('token', '', {
					path: '/',
					domain: process.env.VUE_APP_DOMAIN
				})

				Cookies.remove('token')
				this.$store.commit('userinfo', '')
				Cookies.remove('userinfo')
				


				sessionStorage.clear();
				Cookies.set()



				window.location.href = process.env.VUE_APP_LOGIN_URL

			})
		}


	},
};
</script>

<style lang="scss" scoped>
.ucenterbox {
	width: 140px;
	padding-left: 10px;
	margin-right: 20px;
	height: 30px;
	// width: 100%;
	border-left: solid 1px #deddde;
	display: flex;
	justify-content: space-around;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
}
</style>