import router from './router'
import store from './store'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'


NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist



//路由前置的钩子   存储store当前路由 当request请求时 可获取当前路由进行登录判断
// router.beforeEach((to, from, next) => {
//   store.commit('setRoute',to)
//   const token = Cookies.get('token')
//   if(token){
//     //登录存储token
//     store.commit('setToken',token)
//     //获取用户信息
//     memberInfo().then(res=>{
//       const info = res.data
//       console.log('info',info)
//     })
//   }
//   next()
// })



router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)


  store.commit('setRoute',to)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    // alert(111)
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      // alert(3333)
      const hasGetUserInfo = store.getters.name
      // alert(hasGetUserInfo)
      if (hasGetUserInfo) {
      // const hasRoles = store.getters.roles && store.getters.roles.length > 0
     
      // if (hasRoles) {
        next()
      } else {
        try {
      
          // // get user info
          // await store.dispatch('user/getInfo')

          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const {
            menu
          } = await store.dispatch('user/getInfo')


          // generate accessible routes map based on roles
           const accessRoutes = await store.dispatch('permission/generateRoutes', menu)
          // const accessRoutes = []
           

      
          // // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          })

          // next()


        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          // alert('555')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // alert('permission')
      // other pages that do not have permission to access are redirected to the login page.
      // next(`/login?redirect=${to.path}`)
      window.location.href = process.env.VUE_APP_LOGIN_URL+'?url=' + window.btoa(unescape(encodeURIComponent(window.location)))

      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
