<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { currentCity } from '@/api/login'
export default {
  
  created() {
    this.getCurrentCity()
  },

  computed: {
    sheng: {
      get() {
        return this.$store.state.sheng;
      },
      set(v) {
        this.$store.dispatch("changeSheng", v);
      },
    },
    shi: {
      get() {
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
    qu: {
      get() {
        return this.$store.state.qu;
      },
      set(qu) {
        this.$store.dispatch("changeQu", qu);
      },
    },
    block: {
      get() {
        return this.$store.state.block
      },
      set(v) {
        this.$store.dispatch('changeBlock', v)
      }
    }

  },
  methods: {
    getCurrentCity() {

      if (this.shi == null) {
        currentCity().then(res => {
          this.sheng = res.data.province_id;
          this.shi = res.data.city_id;
          this.qu = res.data.area_id;
          this.block = res.data.plate_id;
        })
      }
    }
  }

}
</script>
<style>
body {
  margin: 0;
  padding: 0;
}
.prevent-scroll {
  /* overflow: hidden; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
