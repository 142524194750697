import {
  asyncRoutes,
  constantRoutes
} from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(menus, route) {
  if (route.meta && route.meta.menu) {
   
    // alert(route)
    return menus.some(menu => route.meta.menu === menu)
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, menus) {
 
  // alert(routes)
  const res = []

  routes.map(route => {
    const tmp = {
      ...route
    }
    if(tmp.children){
      // console.log('=',tmp.children)
     let children =[]
      tmp.children.map(r=>{
      // console.log('==',r)
      // console.log('menu',menus)
       return menus.map(menu=>{
          if(r.meta && r.meta.menu){
            if(menu.name.toString() === r.meta.menu.toString()){
              children.push(r)
            }
          }
        })
      })
      tmp.children = children
      res.push(tmp)
    }
   
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES:  (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)

  }
}

const actions = {
  generateRoutes({commit}, menus) {
    // alert(roles)
    return new Promise(resolve => {
      let accessedRoutes = filterAsyncRoutes(asyncRoutes, menus)
      
   
      commit('SET_ROUTES', accessedRoutes)


      resolve(accessedRoutes)
    })

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
