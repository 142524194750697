import loginRequest from '@/utils/loginRequest'
import request from '@/utils/request'



//密码登录
export function passwordLogin(params) {
  return loginRequest({
    url: '/frontend/members/login',
    method: 'post',
    params
  })
}


//获取省市区
export function currentCity(params) {
  return request({
    url: '/frontend/bigdata/city-plate/city-tree',
    method: 'post',
    params
  })
}



//获取手机验证码
export function getTelphoneMsgCode(params) {
  return loginRequest({
    url: '/frontend/sms/get-code',
    method: 'post',
    params
  })
}

//手机短信验证码登录
export function TelphoneMsgCodeLogin(params) {
  return loginRequest({
    url: '/frontend/members/login',
    method: 'post',
    params
  })
}


//扫码登录
export function scanLogin(params) {
  return loginRequest({
    url: '/frontend/members/login',
    method: 'post',
    params
  })
}



//绑定扫码人员link
export function scanBind(params) {
  return loginRequest({
    url: '/frontend/members/scan-bind',
    method: 'post',
    params
  })
}


//保存注册信息
export function saveProfile(params) {
  return loginRequest({
    url: '/frontend/members/profile',
    method: 'post',
    params
  })
}