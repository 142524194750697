import memberRequest from '@/utils/memberRequest'

//数据vip支付pop弹出框数据
export function bigdatavip(params) {
  return memberRequest({
    url: '/members/bigdata-landdata-vip',
    method: 'post',
    params
  })


}




export function memberInfo(params) {
  return memberRequest({
    url: '/members/info',
    method: 'post',
    params
  })


}





//获取用户信息
export function info(params) {
  return memberRequest({
    url: '/members/info',
    method: 'post',
    params
  })

}



//会员退出
export function logout(params) {
  return memberRequest({
    url: '/members/logout',
    method: 'post',
    params
  })
}