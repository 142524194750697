<template>
  <div class="navitembox">
    <div class="navitemwapper" v-for="items in navItemList" :key="items.text">
      <div class="navItem ">
        <div :class="['menuIndex',{'currented':items.isSelect}] " @click.stop=goto(items)>
          <img v-if="items.isSelect === true" class="navicon" :src="items.icons" />
          <img v-else class="navicon" :src="items.icon" />
          <div class="text">{{items.text}}
            <div class="menuSub" @click.stop="" v-if="items.submenu">
              <div class="subtitle">{{items.text}}</div>
              <div class="subline"></div>
              <ul class="subul">
                <li @click.stop=goto(subitem,true) :class="['subli',{subliselect:subitem.isSelect}]"
                  v-for="subitem in items.submenu" :key="subitem.text">

                  <img v-if="subitem.isSelect === true" class="subicon" :src="subitem.icons" />
                  <img v-else class="subicon" :src="subitem.icon" />

                  <a class="subtext">{{subitem.text}}</a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  props: {
    navItemList: {
      type: Array,
      default: function () {
        return [
          {
            icon: '/pic/sy1.png',
            text: '首页',
            isSelect: true,
            submenu: [
              {
                icon: 'el-icon-aim',
                text: '城市区位',
                isSelect: false,
              },
              {
                icon: 'el-icon-bicycle',
                text: '城市12位',
                isSelect: false,
              },
              {
                icon: 'el-icon-location-outline',
                text: '城市区位3',
                isSelect: true,
              },
            ]
          }
        ]
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    goto(items, issub) {
      this.$emit("updateMenuList", items, issub)
    }

  }


}
</script>
<style lang="scss">
.navitembox {
  width: 100%;
  height: auto;
  padding-top: 20px;
}

.navitemwapper {
  width: 100%;
  height: 100PX;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {

  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  font-size: 14px;
}

.navItem {
  color: #fff;
  height: 70px;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border-radius: 10px;
  margin-top: 40px;


  // background:red;
  .menuIndex {
    height: 70px;
    width: 70px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .navicon {
      width: 30px;
      height: 30px;
    }


    // background:blue;
    .menuSub {

      z-index: 100000;
      display: none;
      position: absolute;
      left: 80px;
      top: 0px;
      width: 160px;
      height: auto;
      background: #FFFFFF;
      box-shadow: 0px 5px 26px 0px rgba(0, 0, 0, 0.1);
      color: #232323;

      .subline {
        width: 150px;
        height: 2px;
        background: #F2F2F2;
        box-shadow: 0px 5px 26px 0px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
      }

      .subtitle {
        height: 58px;
        line-height: 58px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #232323;
        text-shadow: 0px 5px 26px rgba(0, 0, 0, 0.1);
        text-align: center;

      }

      .subul {
        list-style: none;
        margin: 0;
        padding: 0;

        .subli {
          height: 24px;
          color: #232323;
          line-height: 24px;
          text-shadow: 0px 5px 26px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 150px;

          margin: 22px 0;

          .subicon {
            height: 16px;
            width: 16px;
            margin-left: 30px;
            margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #232323;
            font-size: 14px;
            line-height: 20px;
            text-shadow: 0px 5px 26px rgba(0, 0, 0, 0.1);
          }

        }

        .subliselect {
          color: #1da3e0;
          border-left: solid 3px #1da3e0;

          .subicon {
            margin-left: 27px;
            color: #1da3e0;
          }
        }
      }

    }

  }
}

.currented {
  background-color: rgba(40, 167, 225, 1);
}

.navitemwapper:hover {
  .menuSub {
    display: block;
  }
}
</style>